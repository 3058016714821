export default [
  // {
  //   title: "Overview",
  //   icon: "mdi-view-dashboard",
  //   route: "/overview",
  // },
  {
    title: "Frames",
    icon: "mdi-file-upload-outline",
    route: "/frames",
  },
  {
    title: "Banners",
    icon: "mdi-file-upload-outline",
    route: "/banners",
  },
  {
    title: "Popup Image",
    icon: "mdi-file-upload-outline",
    route: "/popup-image",
  },
  {
    title: "Netaji Daily",
    icon: "mdi-calendar-outline",
    route: "/netaji-daily",
  },
  {
    title: "Templates",
    icon: "mdi-file-upload-outline",
    route: "/templates",
  },
  // {
  //   title: "Customers",
  //   icon: "mdi-account-multiple",
  //   route: "/customers",
  // },
  {
    title: "Promo Code",
    icon: "mdi-label",
    route: "/promo_code",
  },
  {
    title: "Political Party",
    icon: "mdi-gamepad-circle",
    route: "/political_party",
  },
  {
    title: "Transaction",
    icon: "mdi-wallet",
    route: "/transaction",
  },
  {
    title: "Contact Queries",
    icon: "mdi-account-box",
    route: "/contactform",
  },
  {
    title: "Notification",
    icon: "mdi-bell",
    route: "/notification",
  },
  {
    title: "User OTP",
    icon: "mdi-bell",
    route: "/user-otp",
  },
];
