<template>
    <div class="otp-request">
        <h2>Request OTP</h2>
        <form @submit.prevent="fetchOTP">
            <label for="mobile">Enter User's Mobile Number:</label>
            <input type="text" id="mobile" v-model="mobileNumber" placeholder="Enter mobile number" required />
            <button type="submit">Get OTP</button>
        </form>

        <div v-if="otp">
            <h4>Phone: {{ mobileNumber }}</h4>
            <h4>OTP: <h2 :style="{ color: 'green' }">{{ otp }}</h2></h4>
        </div>

        <div v-if="errorMessage" class="error">
            <p>{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mobileNumber: '',
            otp: '',
            errorMessage: '',
        };
    },
    methods: {
        async fetchOTP() {
            try {
                // Clear previous OTP and errors
                this.otp = '';
                this.errorMessage = '';

                // Validate mobile number format
                const phonePattern = /^[0-9]{10}$/;
                if (!phonePattern.test(this.mobileNumber)) {
                    this.errorMessage = 'Please enter a valid 10-digit mobile number.';
                    return;
                }

                this.loading = true
                this.$Axios
                    .get('get-otp?phone=' + this.mobileNumber)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data && res.data.otp) this.otp = res.data.otp; // Assume the OTP is returned in 'otp' field
                        else this.errorMessage = res.data.Error;
                        // this.otp = res.data["otp"];
                    })
                    .catch((e) => {
                        console.log(e);
                        this.errorMessage = e;
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } catch (error) {
                this.errorMessage = error.message;
            }
        },
    },
};
</script>

<style scoped>
.otp-request {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ddd;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.error {
    color: red;
    font-size: 14px;
}
</style>