<template>
  <div>
    <v-dialog v-model="imgdialog" max-width="450px">
      <v-card tile>
        <v-card-text>
          <v-container>
            <v-img contain max-height="150" :src="selectedSrc"></v-img>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="
              () => {
                imgdialog = false;
              }
            "
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          Create
        </v-btn>
      </template>

      <v-col class="flex flex-end">
        <v-icon dark medium class="mr-1" @click="dialog = false">
          mdi-close
        </v-icon>
      </v-col>

      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"
            ><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon
            >{{ formTitle }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
                class="my-2 d-flex justify-center"
                v-if="selectedImage"
              >
                <v-img contain max-height="150" :src="selectedImage"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  label="Daily Image"
                  outlined
                  dense
                  @change="getImage"
                  v-model="editedItem.daily_image_url"
                  prepend-icon=""
                  append-icon="mdi-image"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="editedItem.name"
                  :error-messages="nameErrors"
                  required
                  @input="
                    editedIndex == -1 ? $v.editedItem.name.$touch() : null
                  "
                  @blur="editedIndex == -1 ? $v.editedItem.name.$touch() : null"
                  label="Name"
                  append-icon="mdi-rename-box"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="editedItem.logo_position"
                  :items="[
                    'Invisible',
                    /*'Top-Left','Top-Right',*/ 'Bottom-Left',
                    'Bottom-Right',
                  ]"
                  label="Logo position"
                  :error-messages="logo_positionErrors"
                  required
                  @input="
                    editedIndex == -1
                      ? $v.editedItem.logo_position.$touch()
                      : null
                  "
                  @blur="
                    editedIndex == -1
                      ? $v.editedItem.logo_position.$touch()
                      : null
                  "
                  append-icon="mdi-arrow-decision"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="editedItem.is_dp_visible"
                  :items="['Yes', 'No']"
                  label="Profile Visibility"
                  :error-messages="dp_visibilityErrors"
                  required
                  @input="
                    editedIndex == -1
                      ? $v.editedItem.dp_position.$touch()
                      : null
                  "
                  @blur="
                    editedIndex == -1
                      ? $v.editedItem.dp_position.$touch()
                      : null
                  "
                  append-icon="mdi-eye"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-show="editedItem.is_dp_visible == 'Yes'"
                  v-model="editedItem.dp_position"
                  :items="['Bottom-Left', 'Bottom-Right']"
                  label="Profile position"
                  :error-messages="dp_positionErrors"
                  required
                  @input="
                    editedIndex == -1
                      ? $v.editedItem.dp_position.$touch()
                      : null
                  "
                  @blur="
                    editedIndex == -1
                      ? $v.editedItem.dp_position.$touch()
                      : null
                  "
                  append-icon="mdi-arrow-decision"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="editedItem.status"
                  :items="['Active', 'Inactive']"
                  label="Status"
                  :error-messages="statusErrors"
                  required
                  @input="
                    editedIndex == -1 ? $v.editedItem.status.$touch() : null
                  "
                  @blur="
                    editedIndex == -1 ? $v.editedItem.status.$touch() : null
                  "
                  append-icon="mdi-list-status"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  dense
                  outlined
                  v-model="editedItem.type"
                  :items="['All', 'Business', 'Political', 'NGO']"
                  label="Type"
                  :error-messages="typeErrors"
                  required
                  @input="
                    editedIndex == -1 ? $v.editedItem.type.$touch() : null
                  "
                  @blur="editedIndex == -1 ? $v.editedItem.type.$touch() : null"
                  append-icon="mdi-briefcase"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="close"> Cancel </v-btn>
          <v-btn color="success" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon color="white" class="mr-2">mdi-delete</v-icon>
          You sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDelete">Cancel </v-btn>
          <v-btn color="success" @click="deleteItemConfirm">OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="images"
      item-key="name"
      class="elevation-3"
    >
      <template #body="props">
        <draggable
          :list="props.items"
          tag="tbody"
          @start="drag = true"
          @end="onDragEnd"
        >
          <tr v-for="(item, itemIndex) in props.items" :key="itemIndex">
            <td v-for="(header, index) in headers" :key="index">
              <v-avatar
                v-if="header.value == 'daily_image_url'"
                class="profile my-2"
                color="grey"
                size="80"
                tile
                @click="openImage(item.daily_image_url)"
              >
                <v-img
                  contain
                  v-if="item.daily_image_url"
                  :src="item.daily_image_url"
                ></v-img>
              </v-avatar>
              <p v-if="header.value == 'status'">
                {{ item.status == 1 ? "Active" : "Inactive" }}
              </p>
              <div v-if="header.value == 'actions'">
                <v-icon
                  small
                  class="mr-2 warning--text"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)" class="error--text">
                  mdi-delete
                </v-icon>
              </div>
              <p
                v-if="
                  !['daily_image_url', 'status', 'actions'].includes(
                    header.value
                  )
                "
              >
                {{ item[header.value] }}
              </p>
            </td>
          </tr>
        </draggable>
      </template>
      <template v-slot:no-data>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="secondary"
        ></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1"> mdi-tray-remove </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
//import ReFileInputCard from "../components/form/ReFileInputCard";
import draggable from "vuedraggable";

export default {
  name: "DailyImageFolderImage",
  mixins: [validationMixin],
  data: () => ({
    re: 0,
    imgdialog: false,
    selectedSrc: null,
    headers: [
      {
        text: "Daily Image",
        value: "daily_image_url",
        sortable: false,
        align: "start",
      },
      { text: "Download Count", value: "download_count", sortable: false },
      { text: "Name", value: "name", sortable: false },
      //   { text: "SQ", value: "order_sequence", sortable: false },
      //   { text: "ID", value: "id", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    images: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: "",
    editedIndex: -1,
    selectedImageUrl: null,
    editedItem: {
      folder_id: "",
      daily_image_url: null,
      name: "",
      logo_position: "Bottom-Right",
      is_dp_visible: "",
      dp_position: "Bottom-Left",
      status: "",
      type: "",
    },
    defaultItem: {
      folder_id: "",
      daily_image_url: null,
      name: "",
      logo_position: "Bottom-Right",
      is_dp_visible: "",
      dp_position: "Bottom-Left",
      status: "",
      type: "",
    },
    selectedImage: null,
  }),
  created() {
    this.getItems();
  },
  methods: {
    onDragEnd(item) {
      let updateData = {
        order_sequence: item.newIndex + 1,
      };
      this.$Axios({
        method: "put",
        url: "nettazy-daily/" + this.images[item.oldIndex].id,
        data: updateData,
      })
        .then(() => {
          this.getItems();
          this.close();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openImage(src) {
      this.selectedSrc = src;
      this.imgdialog = true;
    },
    editItem(item) {
      this.selectedImage = item.daily_image_url;
      this.editedIndex = this.images.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.status = item.status == 1 ? "Active" : "Inactive";
      this.editedItem.daily_image_url = null;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.images.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteImageItem(this.editedItem.id);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$v.$reset();
      this.error = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedIndex == -1 ? this.addItem() : this.updateItem();
    },
    //Banners API Calls
    async getImage(addedImageDetails) {
      try {
        this.selectedImageUrl = addedImageDetails;

        console.log("base image");
        let formData = new FormData();
        formData.append("daily_file", this.selectedImageUrl);

        let data = await this.$Axios({
          method: "post",
          url: "nettazy-daily/add-daily-image",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(data.data);
        if (data.data.success) {
          this.selectedImage = data.data.data.daily_image_url;
          //   await this.addItem();

          //   let insertData = {};
          //   insertData.daily_image_url = this.selectedImageUrl;
          //   insertData.folder_id = this.folderId;

          //   let insRes = await this.$Axios.post("nettazy-daily", insertData);

          //   console.log(insRes);
          //   await this.getItems();
          //   this.close();
        } else {
          console.log(data.data.msg);
          alert(data.data.msg);
        }
      } catch (e) {
        console.log(e);
        alert(e.response?.data?.msg ?? "Something went wrong!");
      }
    },
    getItems() {
      this.loading = true;
      this.$Axios
        .get("nettazy-daily/get/all/" + this.folderId)
        .then((res) => {
          if (res.data["success"]) {
            this.images = res.data["data"];
          } else {
            alert(res.data["msg"]);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addItem() {
      this.error = "";
      this.$v.$touch();
      console.log(this.$v);
      if (this.$v.$error) {
        return;
      }
      let data = {};
      data.daily_image_url = this.selectedImage;
      data.folder_id = this.folderId;
      data.name = this.editedItem.name;
      data.logo_position = this.editedItem.logo_position;
      data.is_dp_visible = this.editedItem.is_dp_visible;
      if (this.editedItem.is_dp_visible == "Yes")
        data.dp_position = this.editedItem.dp_position;
      data.status = this.editedItem.status == "Active" ? 1 : 0;
      data.type = this.editedItem.type;

      this.$Axios
        .post("nettazy-daily", data)
        .then(() => {
          this.getItems();
          this.close();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateItem() {
      this.error = "";
      this.editedItem.daily_image_url = this.selectedImage;
      this.$v.$touch();

      console.log(this.$v);
      if (this.$v.$error) {
        return;
      }
      let updateData = {};
      if (this.editedItem.name) updateData.name = this.editedItem.name;
      if (this.editedItem.daily_image_url)
        updateData.daily_image_url = this.selectedImage;
      if (this.editedItem.logo_position)
        updateData.logo_position = this.editedItem.logo_position;
      if (this.editedItem.dp_position)
        updateData.dp_position = this.editedItem.dp_position;

      if (this.editedItem.is_dp_visible == "No") {
        updateData.dp_position = "";
        updateData.is_dp_visible = this.editedItem.is_dp_visible;
      } else {
        updateData.dp_position = this.editedItem.dp_position;
        updateData.is_dp_visible = this.editedItem.is_dp_visible;
      }

      if (this.editedItem.status)
        updateData.status = this.editedItem.status == "Active" ? 1 : 0;
      if (this.editedItem.type) updateData.type = this.editedItem.type;

      console.log(`updating item : ${this.editedItem.id}`, updateData);
      this.$Axios({
        method: "put",
        url: "nettazy-daily/" + this.editedItem.id,
        data: updateData,
      })
        .then(() => {
          this.getItems();
          this.close();
        })
        .catch((e) => {
          this.editedItem.daily_image_url = null;
          console.log(e.response);
        });
    },
    deleteImageItem(id) {
      this.$Axios
        .delete("nettazy-daily/" + id)
        .then(() => {
          this.getItems();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    redirectToFolderDetailsView() {
      this.$router.push("/templates");
    },
  },
  computed: {
    folderId() {
      return this.$route.params.folderId;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Daily Image" : "Edit Daily Image";
    },
    formIcon() {
      return this.editedIndex === -1 ? "mdi-plus" : "mdi-edit";
    },
    filtredItems() {
      if (this.filter)
        return this.desserts.filter(
          (item) =>
            item.name.includes(this.filter) ||
            item.license.includes(this.filter) ||
            item.email.includes(this.filter) ||
            item.db.includes(this.filter)
        );
      else return this.desserts;
    },
    //// Vuelidate Errors
    img_urlErrors() {
      const errors = [];
      if (!this.$v.editedItem.daily_image_url.$dirty) return errors;
      !this.$v.editedItem.daily_image_url.required &&
        errors.push("Daily Image is required !");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.required && errors.push("Name is required !");
      return errors;
    },
    logo_positionErrors() {
      const errors = [];
      if (!this.$v.editedItem.logo_position.$dirty) return errors;
      !this.$v.editedItem.logo_position.required &&
        errors.push("Logo position is required");
      return errors;
    },
    dp_visibilityErrors() {
      const errors = [];
      if (!this.$v.editedItem.is_dp_visible.$dirty) return errors;
      !this.$v.editedItem.is_dp_visible.required &&
        errors.push("Is DP Visible is required");
      return errors;
    },
    dp_positionErrors() {
      const errors = [];
      if (!this.$v.editedItem.dp_position.$dirty) return errors;
      !this.$v.editedItem.dp_position.required &&
        errors.push("Position is required");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.editedItem.status.$dirty) return errors;
      !this.$v.editedItem.status.required && errors.push("Status is required");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.editedItem.type.$dirty) return errors;
      !this.$v.editedItem.type.required && errors.push("Type is required");
      return errors;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedItem: {
      daily_image_url: {
        required,
      },
      name: {
        required,
      },
      logo_position: {
        required,
      },
      dp_position: {
        required,
      },
      is_dp_visible: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
    },
  },
  components: {
    //ReFileInputCard: ReFileInputCard,
    draggable,
  },
};
</script>
<style scoped>
button.delete_folder {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}

button.edit_folder {
  position: absolute;
  top: 75px;
  right: 10px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}
</style>

<style scoped>
button.delete_folder {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}
</style>
