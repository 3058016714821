<template>
  <div class="background d-flex justify-center align-center">
    <v-card width="400" height="500">
      <v-card-title >
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" class="text-display-1 font-weight-medium d-flex justify-center">
              Log In
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-subtitle-2 font-weight-light d-flex justify-center">
              Enter your email and password below
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="d-flex justify-center">
          <v-container>
            <form>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="mb-1 font-weight-medium">
                  Email
                </div>
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  prepend-inner-icon="mdi-at"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                <div class="mb-1 font-weight-medium">
                  Password
                </div>
                </div>
                <v-text-field
                  v-model="password"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (showPwd = !showPwd)"
                  :error-messages="passwordErrors"
                  required
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  :type="showPwd ? 'text' : 'password'"
                  outlined
                   prepend-inner-icon="mdi-key"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters >
                <v-col cols="12" class="d-flex justify-center mb-4">
                    <v-alert v-if="error" dense type="error">
                        {{error}}
                    </v-alert>
                    <v-progress-circular
                      class="d-flex justify-center"
                      v-if="loading"
                      :size="50"
                      :width="5"
                      color="accent"
                      indeterminate
                    ></v-progress-circular>
                </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn @click="submit" large block  class="primary white--text mx-4">Login</v-btn>
              </v-col>
            </v-row>
            </form>
          </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { required,  email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapActions } from "vuex"
export default {
  name: 'Login',
  mixins: [validationMixin],
  data: () => ({
    email: '',
    password: '',
    error: '',
    loading: false,
    showPwd: false
  }),
  methods: {
    ...mapActions(["setUser"]),
    submit () {
        this.$v.$touch()
        if(!this.$v.$errors){
            this.error = ''
            this.loading = true
            // Request Body
            let body = {
              user_email: this.email,
              user_password: this.password,
            }
             
            //Login
            this.$Axios.post('login',body)
            .then(res => {
              // Set local storage
                this.setUser({
                  token: res.data["token - Success"],
                  user: res.data.user
                });
              // Clear form
                this.clear();
              // Navigate to the account
                this.$router.push('/templates')
            })
            .catch((err) => {
               this.error="Wrong email or password!"
               console.log(err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      clear () {
        this.$v.$reset()
        this.email = ''
        this.password = ''
        this.error = ''
      },
  },
  computed: {
    emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail !')
        !this.$v.email.required && errors.push('E-mail is required !')
        return errors
      },
    passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Password is required !')
        return errors
      },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
};
</script>
<style scoped>
.background {
  background-color: #ffede1;
  width: 100%;
  height: 100%;
}
</style>